




























import { defineComponent, ref } from '@nuxtjs/composition-api';
import { useUser } from '~/composables';
import { CustomerCreateInput } from '~/modules/GraphQL/types';

export default defineComponent({
    name: 'Register',
    setup() {
    
        const { register } = useUser();
        const newUser = ref<CustomerCreateInput | null>(null); 
        console.log(newUser);

        return {
            registerAction: async (e) => {

                newUser.value = {
                    "firstname": e.target[0].value,
                    "lastname": e.target[1].value,
                    "email": e.target[2].value,
                    "password": e.target[3].value,
                }
                
                const regRes = await register({ user: newUser.value });
                console.log(regRes)
            }
        }
    } 
});

